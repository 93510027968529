import styled from 'styled-components';
import {Commonbtn,SectionSubHeading,SectionHeading} from '../Common/common.style';
import {device} from '../Common/device';
import { TabList} from 'react-tabs';

export const PropertiesSection = styled.section`
    padding:100px 0px 80px;

    @media ${device.tablet} {
        padding:80px 10px 60px;
    }
`;

export const TopLayout = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom:60px;

    @media ${device.tablet} {
        flex-direction:column;
        margin-bottom:45px;
    }
`;

export const HeadingLayout = styled.div`
`;

export const Heading = styled(SectionHeading)`
    margin-bottom:0px;

    @media ${device.tablet} {
        margin-bottom:20px;
    }
`;

export const SubHeading = styled(SectionSubHeading)`
`;

export const CustomTabList = styled(TabList)`
    margin:0px;

    .react-tabs__tab{
        background: #f5f5f5;
        padding: 10px 20px;
        margin-right:10px;
        color: #333;
        font-size:16px;
        border: none;
    }

    .react-tabs__tab--selected{
        color: #ef5350;
        border: none;
        border-radius:0px;
        box-shadow: inset 0px -2px 0px #ef5350;
    }

`;

export const SliderOuterWrapper = styled.div`
    margin:0px -15px;// left/right margin of property layout
`;

export const SliderWrapper = styled.div`
    outline:0;
`;

export const PropertyLayout = styled.div`
    background:#fff;
    box-shadow: 0px 0px 8px 1px #ddd;
    margin:15px;
    padding:30px;
`;

export const PropertyImgWrapper = styled.div`
    .propertyImg{
        min-height:300px;
    }
    
`;
export const SpecLayout = styled.div`

    @media ${device.tablet} {
        margin-top:30px;
    }

    h4{
        margin-top:20px;
    }
`;


export const SpecItemLayout = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:10px;

    p{
        margin-bottom:0px;
        margin-left:10px;
    }
`;

export const SpecIcon = styled.img`
    width:20px;
    flex-shrink:0;
`;


export const Contact = styled(Commonbtn)`
    
`;


export const NextPrev = styled.div`
    text-align:center;
`;

export const Button = styled.button`
    border:none;
    background:none;
    outline:none;
    line-height:1;
    
    :focus{
        outline:0;
    }
`;

export const PrevImg = styled.img`
    margin:20px 0px;
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;

export const NextImg = styled.img`
    margin:20px 0px;
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;