import {createGlobalStyle} from 'styled-components';
import {device} from './device';

const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Muli', sans-serif;
        font-weight: 400;

        .ReactModal__Overlay{
            z-index:9999 !important;
        }
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }
    p{
        font-family: 'Muli', sans-serif;
        font-weight: 400;
        color:#545454;
        margin-bottom:20px;
        font-size:15px;
        line-height:1.5;
    }
    a{
        font-size:16px;
        font-family: 'Muli', sans-serif;
        font-weight:400;
    }
    img{
        margin-bottom:0px;
    }

    h1{
        font-size:48px;
        line-height:54px;
        margin-bottom:20px;
        color:#282828;

        @media ${device.laptop} {
            font-size:42px;
            line-height:48px;
        }

        @media ${device.mobileXL} {
            font-size:38px;
            line-height:44px;
        }
    }
    h2{
        font-size: 42px;
        line-height:48px;
        margin-bottom: 20px;
        color:#282828;

        @media ${device.tablet} {
            font-size:40px;
            line-height:46px;
        }
    }
    h3{
        font-size: 36px;
        line-height:42px;
        margin-bottom: 30px;
        color:#282828;
        text-transform:uppercase;
    }
    h4{
        font-size:30px;
        color:#282828;
        line-height:36px;
        margin-bottom:20px;

        @media ${device.tablet} {
            font-size:28px;
            line-height:32px;
        }
    }
    h5{
        font-size:22px;
        color:#282828;
        line-height:28px;
        margin-bottom:20px;
    }
    h6{
        font-size:18px;
        line-height:24px;
        margin-bottom:20px;
        color:#282828;

        @media ${device.tablet} {
            text-align:left;
        }

        @media ${device.mobileXL} {
            font-size:17px;
            line-height:23px;
        }
    }
    
`;

export default GlobalStyle;