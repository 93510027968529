import React,{Component} from 'react';
import {AboutSection,CustomRow,AboutImg,HeadingLayout,SubHeading,Heading,
    SliderOuterWrapper,AboutGalleryWrapper,Button,
    ImageHolder,NextPrev,PrevImg,NextImg
} from './aboutus.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class AboutUs extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    openDialogBox(imageArray,idx)
    {
        this.props.openLightBoxAbout(imageArray,idx);
    }
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }
    
    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                  breakpoint: 575,
                  settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        rows:1
                    }
                }
            ]
        };
        return (
            <AboutSection id="aboutSection">
                <Container>
                    <CustomRow>
                        <Col lg={5}>
                            <AboutImg>
                                <GatsbyImage image={this.props.AboutData.Img.childImageSharp.gatsbyImageData} alt="" />
                            </AboutImg>
                        </Col>
                        <Col lg={7}>
                            <HeadingLayout>
                                <SubHeading>
                                {this.props.AboutData.SubHeading}
                                </SubHeading>
                                <Heading>
                                {this.props.AboutData.Heading}
                                </Heading>
                            </HeadingLayout>
                            <p>
                            {this.props.AboutData.AboutText1}
                            </p>
                            <p>
                            {this.props.AboutData.AboutText2}
                            </p>
                            <SliderOuterWrapper>
                                <Slider ref={c => (this.slider = c)} {...settings}>
                                {
                                    this.props.AboutData.SliderData.map((imageObj,idx)=>{
                                        return (
                                            <AboutGalleryWrapper>
                                                <ImageHolder onClick={this.openDialogBox.bind(this,this.props.AboutData,idx)}>
                                                    <GatsbyImage
                                                        image={imageObj.Img.childImageSharp.gatsbyImageData}
                                                        alt=""
                                                        className="aboutUsSliderImg" />
                                                </ImageHolder>
                                            </AboutGalleryWrapper>
                                        );
                                    })
                                }
                                </Slider>
                                <NextPrev>
                                    <Button aria-label="Prev Button" onClick={this.previous}>
                                        <PrevImg src={this.props.AboutData.PrevImg} alt=""/>
                                    </Button>
                                    <Button aria-label="Next Button" onClick={this.next}>
                                        <NextImg src={this.props.AboutData.NextImg} alt=""/>
                                    </Button>
                                </NextPrev>
                            </SliderOuterWrapper>
                        </Col>
                    </CustomRow>
                </Container>
            </AboutSection>
        );
    }
}

export default props => (
<StaticQuery
    query={graphql`{
  realestatePage1Json {
    AboutUs {
      Img {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      SubHeading
      Heading
      AboutText1
      AboutText2
      SliderData {
        Img {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
          publicURL
        }
      }
      PrevImg
      NextImg
    }
  }
}
`}
    render={(data) => (
        <AboutUs 
        AboutData={data.realestatePage1Json.AboutUs}
        {...props}
        />
    )}
/>
)
