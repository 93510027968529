import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonbtn,CommonMulih6} from '../Common/common.style';
import {PlayCircle} from '@styled-icons/boxicons-regular/PlayCircle';
import BackgroundImg from '../../../assets/realestate-1-images/banner-1.jpg';

export const BannerSection = styled.section`
    background-image: url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    background-position-x: center;

    min-height: 100vh;
    padding:160px 0px 100px;
    display:flex;
    align-items:center;

    @media ${device.laptopM} {
        background-position-x: 70%;
    }
    @media ${device.laptopM} {
        background-position-x: 90%;
    }
    @media ${device.tablet} {
        background-position-x: 90%;
        padding:140px 10px 80px;
    }
`;

export const BannerContents = styled.div`
    display:flex;
    align-items:center;
`;

export const BannerContentsLeft = styled.div`
    width:55%;

    @media ${device.laptop} {
        width:65%;
    }
    @media ${device.tablet} {
        width:100%;
    }

    h1{
        color:#fff;
        @media ${device.tablet}{
            text-align:center;
        }
    }
`;


export const BannerDescription = styled(CommonMulih6)`
    color:#fff;
    opacity:0.95;
    margin-bottom:30px;

    @media ${device.tablet}{
        text-align:center;
    }
`;

export const ButtonLayout = styled.div`
    display:flex;
    align-items:center;

    @media ${device.tablet} {
        justify-content:center;
    }
    @media ${device.mobileL} {
        flex-direction:column;
    }
`;

export const CallNowBtn = styled(Commonbtn)`

`;

export const WatchVideoAnchor = styled.a`
    cursor:pointer;
    transition: all 0.3s ease-in-out;

    :hover{
        transition: all 0.3s ease-in-out;
        transform:scale(1.05);
    }
`;

export const WatchVideoLayout = styled.div`
    display:flex;
    align-items:center;
    margin-left:40px;
    border-radius:5px;

    @media ${device.mobileL} {
        margin-top:20px;
        margin-left:0px;
    }
`;

export const PlayIcon = styled(PlayCircle)`
    width: 40px;
    height: 40px;
    color:#fff;
`;

export const PlayText = styled.span`
    color:#fff;
    margin-left:5px;
`;


