import React,{Component} from 'react';
import {PropertiesSection,TopLayout,HeadingLayout,SubHeading,Heading,CustomTabList,SliderOuterWrapper,
    SliderWrapper,PropertyLayout,PropertyImgWrapper,SpecLayout,
    SpecItemLayout,SpecIcon,Contact,
    Button,PrevImg,NextImg,NextPrev
} from './properties.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, Tabs, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GatsbyImage } from "gatsby-plugin-image";

class Properties extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                }
            ]
        };
        
        return (
            <PropertiesSection id="propertiesSection">
                <Container>
                    <Tabs>
                        <TopLayout>
                            <HeadingLayout>
                                <SubHeading>
                                    {this.props.PropertiesData.SubHeading}
                                </SubHeading>
                                <Heading>
                                    {this.props.PropertiesData.Heading}
                                </Heading>
                            </HeadingLayout>
                            <CustomTabList>
                            {
                                 this.props.PropertiesData.TabList.map((item,idx) => {
                                    return <Tab>{item.Tab}</Tab>
                                })
                            }
                        </CustomTabList>
                        </TopLayout>
                        {
                             this.props.PropertiesData.TabList.map((itemTab,idxTab) => {
                            return (
                                <TabPanel>
                                        <SliderOuterWrapper>
                                            <Slider ref={c => (this.slider = c)} {...settings}>
                                            {
                                                itemTab.TabPanel.map((itemPanel,idxPanel) => {
                                                return (
                                                    <SliderWrapper>
                                                            <PropertyLayout>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <PropertyImgWrapper>
                                                                            <GatsbyImage
                                                                                image={itemPanel.Img.childImageSharp.gatsbyImageData}
                                                                                alt=""
                                                                                className = "propertyImg" />
                                                                        </PropertyImgWrapper>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <SpecLayout>
                                                                            <h5>
                                                                            {itemPanel.SizeText}
                                                                            </h5>
                                                                            <SpecItemLayout>
                                                                                <SpecIcon src={itemPanel.SpecItemLayout[0].SpecIcon} alt=""/>
                                                                                <p>
                                                                                {itemPanel.SpecItemLayout[0].SpecText} 
                                                                                </p>
                                                                            </SpecItemLayout>
                                                                            <SpecItemLayout>
                                                                                <SpecIcon src={itemPanel.SpecItemLayout[1].SpecIcon} alt=""/>
                                                                                <p>
                                                                                {itemPanel.SpecItemLayout[1].SpecText} 
                                                                                </p>
                                                                            </SpecItemLayout>
                                                                            <SpecItemLayout>
                                                                                <SpecIcon src={itemPanel.SpecItemLayout[2].SpecIcon} alt=""/>
                                                                                <p>
                                                                                {itemPanel.SpecItemLayout[2].SpecText} 
                                                                                </p>
                                                                            </SpecItemLayout>
                                                                            <SpecItemLayout>
                                                                                <SpecIcon src={itemPanel.SpecItemLayout[3].SpecIcon} alt=""/>
                                                                                <p>
                                                                                {itemPanel.SpecItemLayout[3].SpecText} 
                                                                                </p>
                                                                            </SpecItemLayout>
                                                                            <h4>{itemPanel.Price}</h4>
                                                                            <Contact href="#">{itemPanel.Contact}</Contact>
                                                                        </SpecLayout>
                                                                    </Col>
                                                                </Row>
                                                            </PropertyLayout>
                                                        </SliderWrapper>
                                                );
                                                })
                                            }
                                            </Slider>
                                            <NextPrev>
                                                <Button onClick={this.previous} aria-label="Prev Button">
                                                    <PrevImg src={this.props.PropertiesData.PrevImg} alt=""/>
                                                </Button>
                                                <Button onClick={this.next} aria-label="Next Button">
                                                    <NextImg src={this.props.PropertiesData.NextImg} alt=""/>
                                                </Button>
                                            </NextPrev>
                                        </SliderOuterWrapper>
                                    </TabPanel>
                            );
                            })
                        }
                    </Tabs>
                </Container>
            </PropertiesSection>
        );
    }
}


export default () => (
<StaticQuery
    query={graphql`{
  realestatePage1Json {
    Properties {
      SubHeading
      Heading
      TabList {
        Tab
        TabPanel {
          Img {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          SizeText
          SpecItemLayout {
            SpecIcon
            SpecText
          }
          Price
          Contact
        }
      }
      PrevImg
      NextImg
    }
  }
}
`}

    render={(data) => (
        <Properties PropertiesData={data.realestatePage1Json.Properties}/>
    )}
/>
)