import React from 'react';
import {FeaturesSection,FeaturesLayout,FeaturesCard,
FeaturesCardImg,FeaturesCardIcon,FeaturesRow
} from './featurecards.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';

const Features = () => {  
    const JSONData  = useStaticQuery(graphql`
        query{
            realestatePage1Json {
                Features {
                    DelayConstant
                    FeaturesLayout{
                        FeaturesCardIcon
                        FeaturesCardHeading
                        FeaturesCardPara
                    }
                }
            }
        }
    `);
    const FeaturesData = JSONData.realestatePage1Json.Features;
    return(
        <FeaturesSection id="featuresSection">
            <Container>
                <FeaturesRow>
                    <Col md="12" lg="12">
                        <FeaturesLayout>
                            <Row>
                            { 
                                FeaturesData.FeaturesLayout.map((item,idx) => {
                                return <Col lg="4" md="6" sm="12">
                                        <Fade delay={(idx+1)*FeaturesData.DelayConstant}>
                                            <FeaturesCard position={idx+1}>
                                                <h5>{item.FeaturesCardHeading}</h5>
                                                <p>{item.FeaturesCardPara}</p>
                                                <FeaturesCardImg>
                                                    <FeaturesCardIcon src={item.FeaturesCardIcon} alt=""/>
                                                </FeaturesCardImg>
                                            </FeaturesCard>
                                        </Fade>
                                    </Col>
                                })
                            }
                            </Row>
                        </FeaturesLayout>
                    </Col>
                </FeaturesRow>
            </Container>
        </FeaturesSection>
)
}
export default Features;