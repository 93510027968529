import styled from 'styled-components';
import {device} from './device';

export const Commonbtn = styled.a`
    background: #ef5350;
    color: #fff;
    padding: 10px 40px;
    text-decoration:none;
    display:inline-block;
    cursor:pointer;

    :hover{
        background:#e91916;
        text-decoration:none;
        color: #fff;
    }

    @media ${device.mobileXL} {
        padding: 8px 30px;
    }
`;


export const CommonMulih6 = styled.h6`
    font-size:18px;
    line-height:24px;
    margin-bottom:20px;
    color:#282828;
    font-family: 'Muli', sans-serif;

    @media ${device.tablet} {
        text-align:left;
    }

    @media ${device.mobileXL} {
        font-size:17px;
        line-height:23px;
    }
`;



export const SectionHeading = styled.h2`
    text-align:left;
    line-height:1;

    @media ${device.tablet} {
        line-height:1;
    }
`;

export const SectionSubHeading = styled.h5`
    line-height:1;
    margin-bottom:5px;
    text-transform:uppercase;
`;
