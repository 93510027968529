import styled from 'styled-components';
import {device} from '../Common/device';
import BackgroundImg from '../../../assets/realestate-1-images/stats-banner-parallax.jpg'

export const OurStatsSection = styled.section`
    padding:100px 0px 70px;
    background-image:url(${BackgroundImg});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media ${device.laptop} {
        padding:80px 10px 50px;
    }
`;

export const OurStatsLayout = styled.div`
    
`;

export const SingleStatLayout = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:30px;
`;

export const StatImage = styled.img`
    width:80px;
    flex-shrink:0;
`;

export const TextLayout = styled.div`
    margin-left:20px;

    h1{
        color:#fff;
        line-height:1;
        margin-bottom:5px;

        @media ${device.laptop} {
            line-height:1;
        }
        @media ${device.mobileXL} {
            line-height:1;
        }
    }

    p{
        color:#fff;
        margin-bottom:0;
    }
`;

