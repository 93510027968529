import styled from 'styled-components';
import {device} from '../Common/device';
import {SectionSubHeading,SectionHeading} from '../Common/common.style';
import {ChevronRight} from '@styled-icons/boxicons-regular/ChevronRight'
import {ChevronLeft} from '@styled-icons/boxicons-regular/ChevronLeft'

export const GallerySection = styled.section`
    padding:100px 0px 70px;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }

    .slick-slide{
        line-height:0;
    }
`;

export const TopLayout = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const HeadingLayout = styled.div`
`;

export const Heading = styled(SectionHeading)`
    margin-bottom:0px;
`;

export const SubHeading = styled(SectionSubHeading)`
`;

export const ButtonLayout = styled.div`
    display:flex;
    align-items: flex-start;
`;

export const Button = styled.button`
    margin: 0px;
    padding: 0px;
    border: none;
    outline:0;
    margin-right:${props => props.marginRight};

    :focus{
        outline:0;
    }
`;

export const LeftBtn = styled(ChevronLeft)`
    width:40px;
    background:#f5f5f5;
    color:#ef5350;

    :hover{
        background:#e1e1e1;
    }
`;

export const RightBtn = styled(ChevronRight)`
    width:40px;
    background:#f5f5f5;
    color:#ef5350;

    :hover{
        background:#e1e1e1;
    }
`;

export const SliderOuterWrapper = styled.div`
    margin:0px -15px;// left right margin of ImageHolder
`;

export const SliderWrapper = styled.div`
    outline:0;
`;

export const ImageHolder = styled.div`
    margin:0px 15px 30px;
    cursor:pointer;
`;


